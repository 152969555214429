import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 新增安全交底类别
export const AddSecurityCategory=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/AddSecurityCategory`,
        data:datas
    })
}

// 新增安全交底
export const AddSecurityDisclosure=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/AddSecurityDisclosure`,
        data:datas
    })
}

// 安全交底类别列表
export const GetSecurityCategory=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetSecurityCategory`,
        params:datas
    })
}



// 安全交底列表
export const GetSecurityDisclosureList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetSecurityDisclosureList`,
        params:datas
    })
}

// 质量交底列表
export const GetQualityDisclosureList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetQualityDisclosureList`,
        params:datas
    })
}

//获取安全交底数据详情
export const GetSecurityDisclosure=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetSecurityDisclosure`,
        params:datas
    })
}



// 新增修改安全大事记
export const UpdateSecurityBigEvent=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/UpdateSecurityBigEvent`,
        data:datas
    })
}

// 材料出库
export const Update_exWarehouse=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/Update_exWarehouse`,
        data:datas
    })
}

// 新增修改添加入库
export const Insert_materialStorage=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/Insert_materialStorage`,
        data:datas
    })
}

// 新增退料
export const Insert_materialQuit=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/Insert_materialQuit`,
        data:datas
    })
}

// 新增修改添加领料
export const InsertOrUpdate_storesRequisition=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/InsertOrUpdate_storesRequisition`,
        data:datas
    })
}

// 新增修改材料
export const InsertOrUpdate_materia=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_materia`,
        data:datas
    })
}
// 新增修改规格
export const InsertOrUpdate_specifications=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_specifications`,
        data:datas
    })
}
// 新增垃圾分类
export const InsertOrUpdate_garbageSorting=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/api/GreenConstruction/InsertOrUpdate_garbageSorting`,
        data:datas
    })
}
// 新增修改推场
export const InsertOrUpdate_stockDumpArea=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_stockDumpArea`,
        data:datas
    })
}
// 新增vr教育
export const InsertOrUpdate_safetyEducation=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/InsertOrUpdate_safetyEducation`,
        data:datas
    })
}
// 新增工艺模拟
export const InsertOrUpdate_craftSimulation=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/InsertOrUpdate_craftSimulation`,
        data:datas
    })
}

//获取安全大事记详情
export const GetSecurityBigEvent=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetSecurityBigEvent`,
        params:datas
    })
}

// 删除安全交底类别
export const DeleteSecurityCategory=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/DeleteSecurityCategory`,
        params:datas
    })
}
// 获取下拉材料
export const getMateriaList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/getMateriaList`,
        params:datas
    })
}

// 获取下拉堆场
export const getStockDumpAreaList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/getStockDumpAreaList`,
        params:datas
    })
}

// 获取下拉规格
export const getSpecificationsList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/getSpecificationsList`,
        params:datas
    })
}

// 获取下拉角色
export const Get_roleList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_roleList`,
        params:datas
    })
}

// 获取下拉人员
export const Get_userForRoleid=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_userForRoleid`,
        params:datas
    })
}

// 添加编辑编码规则
export const InsertOrUpdate_numberingRule=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_numberingRule`,
        data:datas
    })
}

// 删除安全交底
export const DeleteSecurityDisclosure=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/DeleteSecurityDisclosure`,
        params:datas
    })
}

//获取安全大事列表
export const GetSecurityBigEventList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetSecurityBigEventList`,
        params:datas
    })
}

//获取出库列表
export const Get_exWarehousePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/Get_exWarehousePageList`,
        params:datas
    })
}

//获取入库列表
export const Get_materialStoragePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/Get_materialStoragePageList`,
        params:datas
    })
}

//获取退料列表
export const Get_materialQuitPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/Get_materialQuitPageList`,
        params:datas
    })
}

//获取领料列表
export const Get_storesRequisitionPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/Get_storesRequisitionPageList`,
        params:datas
    })
}
//获取材料列表
export const Get_materiaPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_materiaPageList`,
        params:datas
    })
}
//获取规格列表
export const Get_specificationsPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_specificationsPageList`,
        params:datas
    })
}
//获取建筑垃圾分类列表
export const Get_constructionWastePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/api/GreenConstruction/Get_constructionWastePageList`,
        params:datas
    })
}
//获取垃圾分类列表
export const Get_garbageSortingPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/api/GreenConstruction/Get_garbageSortingPageList`,
        params:datas
    })
}

//获取堆场位置
export const Get_stockDumpAreaPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_stockDumpAreaPageList`,
        params:datas
    })
}

//获取材料编码规则
export const Get_numberingRulePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_numberingRulePageList`,
        params:datas
    })
}

//获取水电超标的值
export const Get_wae_ThresholdValueData=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_wae_ThresholdValueData`,
        params:datas
    })
}

//获取任务预警值
export const Get_taskEarlyWarning=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_taskEarlyWarning`,
        params:datas
    })
}

//获取库存提醒
export const Get_inventoryWarningPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_inventoryWarningPageList`,
        params:datas
    })
}

//添加水电超标值
export const InsertOrUpdate_wae_ThresholdValue=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_wae_ThresholdValue`,
        data:datas
    })
}

//添加修改任务预警值
export const InsertOrUpdate_taskEarlyWarning=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_taskEarlyWarning`,
        data:datas
    })
}

//删除库存提醒
export const Delete_inventoryWarning=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Delete_inventoryWarning`,
        data:datas
    })
}

//添加\库存提醒
export const InsertOrUpdate_inventoryWarning=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_inventoryWarning`,
        data:datas
    })
}
//获取告警音频
export const Get_alarmAudio=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Get_alarmAudio`,
        params:datas
    })
}
//添加告警音频
export const InsertOrUpdate_alarmAudio=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_alarmAudio`,
        data:datas
    })
}
//获取vr教育列表
export const Get_safetyEducationPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/Get_safetyEducationPageList`,
        params:datas
    })
}

//获取质量大事列表
export const GetQualityBigEventList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetQualityBigEventList`,
        params:datas
    })
}

//删除安全大事记
export const DeleteSecurityBigEvent=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/DeleteSecurityBigEvent`,
        params:datas
    })
}

//删除领料
export const Delete_storesRequisition=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/api/Material/Delete_storesRequisition`,
        data:datas
    })
}
//删除材料
export const Delete_materia=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Delete_materia`,
        data:datas
    })
}
//删除规格
export const Delete_specifications=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Delete_specifications`,
        data:datas
    })
}
//删除建筑垃圾分类
export const Delete_constructionWaste=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/api/GreenConstruction/Delete_constructionWaste`,
        data:datas
    })
}
//删除垃圾分类
export const Delete_garbageSorting=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/api/GreenConstruction/Delete_garbageSorting`,
        data:datas
    })
}

//删除堆场
export const Delete_stockDumpArea=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Delete_stockDumpArea`,
        data:datas
    })
}

//删除材料编码规则
export const Delete_numberingRule=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/Delete_numberingRule`,
        data:datas
    })
}

//删除vr教育
export const Delete_safetyEducation=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/Delete_safetyEducation`,
        data:datas
    })
}

//删除工艺模拟
export const Delete_craftSimulation=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/Delete_craftSimulation`,
        data:datas
    })
}

// 删除质量样板
export const DeleteQualityTemplet=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/DeleteQualityTemplet`,
        params:datas
    })
}


//新增修改质量样板
export const UpdateQualityTemplet=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/UpdateQualityTemplet`,
        data:datas
    })
}

//获取质量样板列表
export const GetQualityTempletList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetQualityTempletList`,
        params:datas
    })
}
//获取质量样板详情
export const GetQualityTemplet=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetQualityTemplet`,
        params:datas
    })
}

//新增修改重大危险源
export const UpdateDangerousSource=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/Safety/UpdateDangerousSource`,
        data:datas
    })
}

// 删除重大危险源
export const DeleteDangerousSource=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/DeleteDangerousSource`,
        params:datas
    })
}

//获取重大危险源详情
export const GetDangerousSource=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetDangerousSource`,
        params:datas
    })
}



// 获取重大危险源列表(分页)
export const GetDangerousSourceList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/GetDangerousSourceList`,
        params:datas
    })
}

// 获取工艺列表
export const Get_craftSimulationPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/Safety/Get_craftSimulationPageList`,
        params:datas
    })
}